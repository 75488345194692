<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2">
        Faktury
      </h6>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="accountSwitch1"
          :checked="true"
          name="check-button"
          switch
          inline
        >
          <span>Faktury</span>
        </b-form-checkbox>

        <b-form-checkbox
          id="accountSwitch2"
          :checked="false"
          name="check-button"
          switch
          inline
        >
          <span>Faktura marża</span>
        </b-form-checkbox>

        <b-form-checkbox
          id="accountSwitch3"
          :checked="false"
          name="check-button"
          switch
          inline
        >
          <span>Faktura proforma</span>
        </b-form-checkbox>

        <b-form-checkbox
          id="accountSwitch4"
          :checked="false"
          name="check-button"
          switch
          inline
        >
          <span>Faktura walutowa</span>
        </b-form-checkbox>
      </b-col>

    </b-row>

    <b-row>
      <h6 class="section-label mx-1 mb-2">
        Noty
      </h6>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="accountSwitch5"
          :checked="true"
          name="check-button"
          switch
          inline
        >
          <span>Noty księgowe</span>
        </b-form-checkbox>

        <b-form-checkbox
          id="accountSwitch6"
          :checked="true"
          name="check-button"
          switch
          inline
        >
          <span>Noty korygujące</span>
        </b-form-checkbox>

      </b-col>

    </b-row>

    <b-row>
      <h6 class="section-label mx-1 mb-2">
        Pozostałe dokumenty
      </h6>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="accountSwitch7"
          :checked="true"
          name="check-button"
          switch
          inline
        >
          <span>Paragony</span>
        </b-form-checkbox>

        <b-form-checkbox
          id="accountSwitch8"
          :checked="true"
          name="check-button"
          switch
          inline
        >
          <span>Dowody wewnętrzne</span>
        </b-form-checkbox>

        <b-form-checkbox
          id="accountSwitch9"
          :checked="true"
          name="check-button"
          switch
          inline
        >
          <span>Turbo faktury</span>
        </b-form-checkbox>

        <b-form-checkbox
          id="accountSwitch10"
          :checked="true"
          name="check-button"
          switch
          inline
        >
          <span>KP Dowód wpłaty</span>
        </b-form-checkbox>

      </b-col>

    </b-row>

    <b-row>

      <b-col>
        <b-form-checkbox
          id="accountSwitch11"
          :checked="true"
          name="check-button"
          switch
          inline
        >
          <span>Zamówienia</span>
        </b-form-checkbox>

        <b-form-checkbox
          id="accountSwitch12"
          :checked="true"
          name="check-button"
          switch
          inline
        >
          <span>Korekty</span>
        </b-form-checkbox>

        <b-form-checkbox
          id="accountSwitch13"
          :checked="true"
          name="check-button"
          switch
          inline
        >
          <span>MP</span>
        </b-form-checkbox>

        <b-form-checkbox
          id="accountSwitch14"
          :checked="true"
          name="check-button"
          switch
          inline
        >
          <span>Odwrotne obciążenie</span>
        </b-form-checkbox>
      </b-col>

      <!-- buttons -->
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1 mt-1"
        >
          Save changes
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          class="mt-1"
          variant="outline-secondary"
        >
          Cancel
        </b-button>
      </b-col>
      <!--/ buttons -->

    </b-row>

  </b-card>
</template>

<script>
import {
  BButton, BRow, BCol, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {

  },
  data() {
    return {

    }
  },
}
</script>
