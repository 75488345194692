<template>
  <users-list />
</template>

<script>
// eslint-disable-next-line import/extensions
import UsersList from '@/views/apps/user/users-list/UsersList'

export default {
  components: { UsersList },
}
</script>

<style>

</style>
