<template>
  <b-card
    no-body
  >
    <b-modal
      id="modal-2"
      class="w-100"
      centered
      size="md"
      hide-footer
      hide-header
      title="Dodaj rolę"
    >
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Nazwa roli"
            label-for="role-name"
          >
            <b-form-input
              id="role-name"
              v-model="newRoleName"
              type="text"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group
            v-for="(permission, index) in permissions"
            :key="index"
            v-model="newRoleName"
            :label="`Uprawnienia do '${permission.module}'`"
            label-for="role-permissions"
          >
            <b-form-checkbox
              :key="index"
              v-model="newRolePermissions.odczyt"
              :checked="permission.odczyt"
              :disabled="permission.odczyt === 'disabled'"
            >
              Odczyt
            </b-form-checkbox>
            <b-form-checkbox
              :key="index"
              v-model="newRolePermissions.edycja"
              :checked="permission.edycja"
              :disabled="permission.edycja === 'disabled'"
            >
              Edycja
            </b-form-checkbox>
            <b-form-checkbox
              :key="index"
              v-model="newRolePermissions.dodawanie"
              :checked="permission.dodawanie"
              :disabled="permission.dodawanie === 'disabled'"
            >
              Dodawanie
            </b-form-checkbox>
            <b-form-checkbox
              :key="index"
              v-model="newRolePermissions.usuwanie"
              :checked="permission.usuwanie"
              :disabled="permission.usuwanie === 'disabled'"
            >
              Usuwanie
            </b-form-checkbox>
          </b-form-group>
          <b-button
            variant="primary"
            @click="createRole"
          >
            Zapisz rolę
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-card-body>
      <b-card-title>Lista uprawnień użytkownika</b-card-title>
      <b-card-sub-title>Uprawnienia przypisane do ról</b-card-sub-title>
      <b-tabs
        pills
        class="mt-2"
      >
        <b-tab
          v-for="(role, index) in roles"
          :key="index"
          @click="getPermissions(role.Name);activeRole = role.id"
        >
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="18"
              class="mr-50"
            />
            {{ role.Name }}
          </template>
        </b-tab>
      </b-tabs>
      <b-button
        v-b-modal.modal-2
        variant="primary"
      >
        <feather-icon
          icon="PlusIcon"
          size="18"
          class="mr-50"
        />
        Dodaj nową rolę
      </b-button>
    </b-card-body>
    <b-table
      striped
      responsive
      :items="permissions"
      class="mb-0"
    >

      <template #cell(module)="data">
        {{ data.value }}
      </template>
      <template #cell()="data">
        <b-form-checkbox
          v-model="data.value"
          :checked="data.value"
        />
      </template>
    </b-table>
    <b-button
      variant="primary"
      class="mt-2"
      @click="updateRole(activeRole)"
    >
      Zapisz
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BButton, BTabs, BTab,
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  components: {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BButton,
    BTabs,
    BTab,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      users: [],
      roles: [],
      permissions: [],
      activeRole: null,
      newRoleName: '',
      newRolePermissions: {
        odczyt: false,
        edycja: false,
        dodawanie: false,
        usuwanie: false,
      },
    }
  },
  mounted() {
    this.getRoles()
  },
  methods: {
    getRoles() {
      const token = localStorage.getItem('accessToken')
      const config = { headers: { Authorization: `Bearer ${token}` } }
      axios.get(`${process.env.VUE_APP_API_URL}Roles`, config).then(response => {
        this.roles = response.data
        this.activeRole = this.activeRole === null ? this.roles[0].id : this.roles.filter(role => role.id === this.activeRole)[0].id
        const activeRoleName = this.roles.filter(role => role.id === this.activeRole)[0].Name
        this.getPermissions(activeRoleName)
      }).catch(error => {
        console.log(error)
      })
    },
    getPermissions(roleId) {
      const token = localStorage.getItem('accessToken')
      const config = { headers: { Authorization: `Bearer ${token}` } }
      axios.get(`${process.env.VUE_APP_API_URL}Roles`, config).then(response => {
        const permissions = response.data.filter(permissionSet => permissionSet.Name === roleId)
        this.permissions = []
        this.permissions.push({
          module: 'Wszystkie moduły',
          odczyt: Boolean(permissions[0].ADMIN_READ),
          edycja: Boolean(permissions[0].ADMIN_WRITE),
          dodawanie: Boolean(permissions[0].ADMIN_CREATE),
          usuwanie: Boolean(permissions[0].ADMIN_DELETE),
        })
      }).catch(error => {
        console.log(error)
      })
    },
    createRole() {
      const token = localStorage.getItem('accessToken')
      const config = { headers: { Authorization: `Bearer ${token}` } }
      const postData = {
        Name: this.newRoleName,
        ADMIN_READ: this.newRolePermissions.odczyt,
        ADMIN_WRITE: this.newRolePermissions.edycja,
        ADMIN_CREATE: this.newRolePermissions.dodawanie,
        ADMIN_DELETE: this.newRolePermissions.usuwanie,
      }
      axios.post(`${process.env.VUE_APP_API_URL}Roles`, postData, config).then(() => {
        this.getRoles()
        Swal.fire('Pomyślnie utworzono rolę', '', 'success')
      }).catch(error => {
        console.log(error)
        Swal.fire('Coś poszło nie tak', '', 'error')
      })
    },
    updateRole(roleId) {
      const token = localStorage.getItem('accessToken')
      const config = { headers: { Authorization: `Bearer ${token}` } }
      const putData = {
        ADMIN_READ: this.permissions[0].odczyt,
        ADMIN_WRITE: this.permissions[0].edycja,
        ADMIN_CREATE: this.permissions[0].dodawanie,
        ADMIN_DELETE: this.permissions[0].usuwanie,
      }
      axios.put(`${process.env.VUE_APP_API_URL}Roles/${roleId}`, putData, config).then(() => {
        Swal.fire('Zaktualizowano rolę', '', 'success')
      }).catch(error => {
        if (error.response.status === 403) {
          Swal.fire('Rola systemowa - nie możesz jej edytować', '', 'error')
        }
      })
    },
  },
}
</script>

<style>

</style>
